import { default as _91_46_46_46slug_93XOh4cis8EcMeta } from "/app/src/pages/[...slug].vue?macro=true";
import { default as _91product_93_45campaignpz5CdX4rZCMeta } from "/app/src/pages/[product]-campaign.vue?macro=true";
import { default as index6dle6k33gZMeta } from "/app/src/pages/about-us/index.vue?macro=true";
import { default as contactmqHVTjI8koMeta } from "/app/src/pages/contact.vue?macro=true";
import { default as cookiesljJJ99na9gMeta } from "/app/src/pages/cookies.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91slug_93hwEhpzXwWtMeta } from "/app/src/pages/inspiration/[slug].vue?macro=true";
import { default as indexwWiL1Z4orWMeta } from "/app/src/pages/inspiration/index.vue?macro=true";
import { default as _91slug_93D9p4qGXTBQMeta } from "/app/src/pages/products/[slug].vue?macro=true";
import { default as indexwnrgOfBd2dMeta } from "/app/src/pages/products/index.vue?macro=true";
import { default as _91slug_93bOuhFHXO95Meta } from "/app/src/pages/recipes/[slug].vue?macro=true";
import { default as shop_45finderbA7qcl6TxnMeta } from "/app/src/pages/shop-finder.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93XOh4cis8EcMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93XOh4cis8EcMeta?.path ?? "/en/:country/:slug(.*)*",
    meta: _91_46_46_46slug_93XOh4cis8EcMeta || {},
    alias: _91_46_46_46slug_93XOh4cis8EcMeta?.alias || [],
    redirect: _91_46_46_46slug_93XOh4cis8EcMeta?.redirect,
    component: () => import("/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93XOh4cis8EcMeta?.name ?? "slug___ar",
    path: _91_46_46_46slug_93XOh4cis8EcMeta?.path ?? "/ar/:country/:slug(.*)*",
    meta: _91_46_46_46slug_93XOh4cis8EcMeta || {},
    alias: _91_46_46_46slug_93XOh4cis8EcMeta?.alias || [],
    redirect: _91_46_46_46slug_93XOh4cis8EcMeta?.redirect,
    component: () => import("/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91product_93_45campaignpz5CdX4rZCMeta?.name ?? "product-campaign___en",
    path: _91product_93_45campaignpz5CdX4rZCMeta?.path ?? "/en/:country/:product()-campaign",
    meta: _91product_93_45campaignpz5CdX4rZCMeta || {},
    alias: _91product_93_45campaignpz5CdX4rZCMeta?.alias || [],
    redirect: _91product_93_45campaignpz5CdX4rZCMeta?.redirect,
    component: () => import("/app/src/pages/[product]-campaign.vue").then(m => m.default || m)
  },
  {
    name: _91product_93_45campaignpz5CdX4rZCMeta?.name ?? "product-campaign___ar",
    path: _91product_93_45campaignpz5CdX4rZCMeta?.path ?? "/ar/:country/:product()-campaign",
    meta: _91product_93_45campaignpz5CdX4rZCMeta || {},
    alias: _91product_93_45campaignpz5CdX4rZCMeta?.alias || [],
    redirect: _91product_93_45campaignpz5CdX4rZCMeta?.redirect,
    component: () => import("/app/src/pages/[product]-campaign.vue").then(m => m.default || m)
  },
  {
    name: index6dle6k33gZMeta?.name ?? "about-us___en",
    path: index6dle6k33gZMeta?.path ?? "/en/:country/about-us",
    meta: index6dle6k33gZMeta || {},
    alias: index6dle6k33gZMeta?.alias || [],
    redirect: index6dle6k33gZMeta?.redirect,
    component: () => import("/app/src/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: index6dle6k33gZMeta?.name ?? "about-us___ar",
    path: index6dle6k33gZMeta?.path ?? "/ar/:country/about-us",
    meta: index6dle6k33gZMeta || {},
    alias: index6dle6k33gZMeta?.alias || [],
    redirect: index6dle6k33gZMeta?.redirect,
    component: () => import("/app/src/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: contactmqHVTjI8koMeta?.name ?? "contact___en",
    path: contactmqHVTjI8koMeta?.path ?? "/en/:country/contact",
    meta: contactmqHVTjI8koMeta || {},
    alias: contactmqHVTjI8koMeta?.alias || [],
    redirect: contactmqHVTjI8koMeta?.redirect,
    component: () => import("/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactmqHVTjI8koMeta?.name ?? "contact___ar",
    path: contactmqHVTjI8koMeta?.path ?? "/ar/:country/contact",
    meta: contactmqHVTjI8koMeta || {},
    alias: contactmqHVTjI8koMeta?.alias || [],
    redirect: contactmqHVTjI8koMeta?.redirect,
    component: () => import("/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: cookiesljJJ99na9gMeta?.name ?? "cookies___en",
    path: cookiesljJJ99na9gMeta?.path ?? "/en/:country/cookies",
    meta: cookiesljJJ99na9gMeta || {},
    alias: cookiesljJJ99na9gMeta?.alias || [],
    redirect: cookiesljJJ99na9gMeta?.redirect,
    component: () => import("/app/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiesljJJ99na9gMeta?.name ?? "cookies___ar",
    path: cookiesljJJ99na9gMeta?.path ?? "/ar/:country/cookies",
    meta: cookiesljJJ99na9gMeta || {},
    alias: cookiesljJJ99na9gMeta?.alias || [],
    redirect: cookiesljJJ99na9gMeta?.redirect,
    component: () => import("/app/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index___en",
    path: indexdPwi6hPdMfMeta?.path ?? "/en/:country",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index___ar",
    path: indexdPwi6hPdMfMeta?.path ?? "/ar/:country",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hwEhpzXwWtMeta?.name ?? "inspiration-slug___en",
    path: _91slug_93hwEhpzXwWtMeta?.path ?? "/en/:country/inspiration/:slug()",
    meta: _91slug_93hwEhpzXwWtMeta || {},
    alias: _91slug_93hwEhpzXwWtMeta?.alias || [],
    redirect: _91slug_93hwEhpzXwWtMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hwEhpzXwWtMeta?.name ?? "inspiration-slug___ar",
    path: _91slug_93hwEhpzXwWtMeta?.path ?? "/ar/:country/inspiration/:slug()",
    meta: _91slug_93hwEhpzXwWtMeta || {},
    alias: _91slug_93hwEhpzXwWtMeta?.alias || [],
    redirect: _91slug_93hwEhpzXwWtMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexwWiL1Z4orWMeta?.name ?? "inspiration___en",
    path: indexwWiL1Z4orWMeta?.path ?? "/en/:country/inspiration",
    meta: indexwWiL1Z4orWMeta || {},
    alias: indexwWiL1Z4orWMeta?.alias || [],
    redirect: indexwWiL1Z4orWMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: indexwWiL1Z4orWMeta?.name ?? "inspiration___ar",
    path: indexwWiL1Z4orWMeta?.path ?? "/ar/:country/inspiration",
    meta: indexwWiL1Z4orWMeta || {},
    alias: indexwWiL1Z4orWMeta?.alias || [],
    redirect: indexwWiL1Z4orWMeta?.redirect,
    component: () => import("/app/src/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93D9p4qGXTBQMeta?.name ?? "products-slug___en",
    path: _91slug_93D9p4qGXTBQMeta?.path ?? "/en/:country/products/:slug()",
    meta: _91slug_93D9p4qGXTBQMeta || {},
    alias: _91slug_93D9p4qGXTBQMeta?.alias || [],
    redirect: _91slug_93D9p4qGXTBQMeta?.redirect,
    component: () => import("/app/src/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93D9p4qGXTBQMeta?.name ?? "products-slug___ar",
    path: _91slug_93D9p4qGXTBQMeta?.path ?? "/ar/:country/products/:slug()",
    meta: _91slug_93D9p4qGXTBQMeta || {},
    alias: _91slug_93D9p4qGXTBQMeta?.alias || [],
    redirect: _91slug_93D9p4qGXTBQMeta?.redirect,
    component: () => import("/app/src/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexwnrgOfBd2dMeta?.name ?? "products___en",
    path: indexwnrgOfBd2dMeta?.path ?? "/en/:country/products",
    meta: indexwnrgOfBd2dMeta || {},
    alias: indexwnrgOfBd2dMeta?.alias || [],
    redirect: indexwnrgOfBd2dMeta?.redirect,
    component: () => import("/app/src/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnrgOfBd2dMeta?.name ?? "products___ar",
    path: indexwnrgOfBd2dMeta?.path ?? "/ar/:country/products",
    meta: indexwnrgOfBd2dMeta || {},
    alias: indexwnrgOfBd2dMeta?.alias || [],
    redirect: indexwnrgOfBd2dMeta?.redirect,
    component: () => import("/app/src/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bOuhFHXO95Meta?.name ?? "recipes-slug___en",
    path: _91slug_93bOuhFHXO95Meta?.path ?? "/en/:country/recipes/:slug()",
    meta: _91slug_93bOuhFHXO95Meta || {},
    alias: _91slug_93bOuhFHXO95Meta?.alias || [],
    redirect: _91slug_93bOuhFHXO95Meta?.redirect,
    component: () => import("/app/src/pages/recipes/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bOuhFHXO95Meta?.name ?? "recipes-slug___ar",
    path: _91slug_93bOuhFHXO95Meta?.path ?? "/ar/:country/recipes/:slug()",
    meta: _91slug_93bOuhFHXO95Meta || {},
    alias: _91slug_93bOuhFHXO95Meta?.alias || [],
    redirect: _91slug_93bOuhFHXO95Meta?.redirect,
    component: () => import("/app/src/pages/recipes/[slug].vue").then(m => m.default || m)
  },
  {
    name: shop_45finderbA7qcl6TxnMeta?.name ?? "shop-finder___en",
    path: shop_45finderbA7qcl6TxnMeta?.path ?? "/en/:country/shop-finder",
    meta: shop_45finderbA7qcl6TxnMeta || {},
    alias: shop_45finderbA7qcl6TxnMeta?.alias || [],
    redirect: shop_45finderbA7qcl6TxnMeta?.redirect,
    component: () => import("/app/src/pages/shop-finder.vue").then(m => m.default || m)
  },
  {
    name: shop_45finderbA7qcl6TxnMeta?.name ?? "shop-finder___ar",
    path: shop_45finderbA7qcl6TxnMeta?.path ?? "/ar/:country/shop-finder",
    meta: shop_45finderbA7qcl6TxnMeta || {},
    alias: shop_45finderbA7qcl6TxnMeta?.alias || [],
    redirect: shop_45finderbA7qcl6TxnMeta?.redirect,
    component: () => import("/app/src/pages/shop-finder.vue").then(m => m.default || m)
  }
]